import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import {
  IoGridOutline,
  IoListOutline,
  IoLockOpenOutline,
  IoSettingsOutline,
} from "react-icons/io5";
import { FiCheck, FiRefreshCw, FiShoppingCart, FiTruck } from "react-icons/fi";

//internal import
import Layout from "../../Layout/Layout";
import Card from "./Card";
import { HiOutlineDocumentText } from "react-icons/hi";
import { FaUserAlt } from "react-icons/fa";
import RecentOrders from "./RecentOrders";
import { Backdrop, CircularProgress, Skeleton } from "@mui/material";
import dayjs from "dayjs";

// import { userSidebar } from '@utils/data';
// import Card from '@component/order-card/Card';
// import { UserContext } from '@context/UserContext';
// import OrderServices from '@services/OrderServices';
// import RecentOrder from '@pages/user/recent-order';
// import { SidebarContext } from '@context/SidebarContext';
// import Loading from '@component/preloader/Loading';

const Dashboard = ({ children }) => {
  const Navigate = useNavigate();
  //   const {
  //     dispatch,
  //     state: { userInfo },
  //   } = useContext(UserContext);
  //   const { isLoading, setIsLoading, currentPage } = useContext(SidebarContext);

  //   const [data, setData] = useState({});
  //   const [error, setError] = useState('');
  //   const [loading, setLoading] = useState(true);

  //   useEffect(() => {
  //     OrderServices.getOrderByUser({
  //       page: currentPage,
  //       limit: 8,
  //     })
  //       .then((res) => {
  //         setData(res);
  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         setError(err.message);
  //       });
  //   }, [currentPage]);

  //   const handleLogOut = () => {
  //     dispatch({ type: 'USER_LOGOUT' });
  //     Cookies.remove('userInfo');
  //     Cookies.remove('couponInfo');
  //     Navigate('/');
  //   };

  //   useEffect(() => {
  //     setIsLoading(false);
  //     if (!userInfo) {
  //       Navigate('/');
  //     }
  //   }, [userInfo]);

  //   console.log('dashbaord');

  const handleLogout = () => {
    localStorage.removeItem("isLogin");
    localStorage.removeItem("Token");
    localStorage.removeItem("customer_id");
    localStorage.removeItem("Product ID");
    localStorage.removeItem("CouponID");
    localStorage.removeItem("Address ID");
    Navigate("/");
  };

  const [getData, setGetData] = useState([]);
  const GetOrderHistory = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_BASE_URL +
        `DetailByCustomerId/${localStorage.getItem("customer_id")}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setGetData(result.data);
        if (result.status === 200) {
          setLoad(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    GetOrderHistory();
    console.log("Order Data:  ", JSON.stringify(getData));
  }, []);
  const handleClickScroll = () => {
    const element = document.getElementById("section-1");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);

  useEffect(
    () => async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      await fetch(
        process.env.REACT_APP_BASE_URL +
          `DashboardDatabyuserID/${localStorage.getItem("customer_id")}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log("Dashboard Data FS:", result);
          setData(result.data);
          if (result.status === 200) {
            setLoad(false);
          } else {
            setLoad(false);
            console.log("ERROR:", result.message);
          }
        })
        .catch((error) => console.log("error", error));
    },
    []
  );

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Layout>
        <div className="mx-auto max-w-screen-2xl px-3 sm:px-10">
          <div className="py-10 lg:py-12 flex flex-col lg:flex-row w-full">
            <div className="flex-shrink-0 w-full lg:w-80 mr-7 lg:mr-10  xl:mr-10 ">
              <div className="bg-white p-4 sm:p-5 lg:p-8 rounded-md sticky top-32">
                <span className="p-2 my-2 flex font-serif items-center rounded-md hover:bg-gray-50 w-full hover:text-emerald-600">
                  <IoGridOutline
                    className="flex-shrink-0 h-4 w-4"
                    aria-hidden="true"
                  />
                  <Link to="/dashboard" onClick={() => Navigate("dashboard1")}>
                    <p className="inline-flex items-center justify-between ml-2 text-sm font-medium w-full hover:text-emerald-600 font-sans">
                      Dashboard
                    </p>
                  </Link>
                </span>
                <span className="p-2 my-2 flex items-center rounded-md hover:bg-gray-50 w-full hover:text-emerald-600 font-sans">
                  <IoListOutline
                    className="flex-shrink-0 h-4 w-4"
                    aria-hidden="true"
                  />
                  <Link to="/recent-orders">
                    <p className="inline-flex items-center justify-between ml-2 text-sm font-medium w-full hover:text-emerald-600 font-sans">
                      My Orders
                    </p>
                  </Link>
                </span>
                <span className="p-2 my-2 flex items-center rounded-md hover:bg-gray-50 w-full hover:text-emerald-600 font-sans">
                  <IoSettingsOutline
                    className="flex-shrink-0 h-4 w-4"
                    aria-hidden="true"
                  />
                  <Link to="/update-profile">
                    <p className="inline-flex items-center justify-between ml-2 text-sm font-medium w-full hover:text-emerald-600">
                      Update Profile
                    </p>
                  </Link>
                </span>
                <span className="p-2 my-2 flex items-center rounded-md hover:bg-gray-50 w-full hover:text-emerald-600 font-sans">
                  <HiOutlineDocumentText
                    className="flex-shrink-0 h-4 w-4"
                    aria-hidden="true"
                  />
                  <Link to="/update-password">
                    <p className="inline-flex items-center justify-between ml-2 text-sm font-medium w-full hover:text-emerald-600">
                      Change Password
                    </p>
                  </Link>
                </span>
                <span className="p-2 flex font-serif items-center rounded-md hover:bg-gray-50 w-full hover:text-emerald-600">
                  <span className="mr-2">
                    <IoLockOpenOutline />
                  </span>
                  <button
                    className="inline-flex items-center justify-between text-sm font-medium w-full hover:text-emerald-600 font-sans"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </span>
              </div>
            </div>
            <div className="w-full bg-white mt-4 lg:mt-0 p-4 sm:p-5 lg:p-8 rounded-md overflow-hidden">
              {!children && (
                <div className="overflow-hidden">
                  <h2 className="text-xl font-serif font-semibold mb-5">
                    Dashboard
                  </h2>

                  <div className="grid gap-4 mb-8 md:grid-cols-2 xl:grid-cols-4">
                    <Card
                      title="Total Orders"
                      Icon={FiShoppingCart}
                      quantity={data?.total_orders}
                      className="text-red-600  bg-red-200"
                    />
                    <Card
                      title="Pending Orders"
                      Icon={FiRefreshCw}
                      quantity={data?.pending_orders}
                      className="text-orange-600 bg-orange-200"
                    />
                    <Card
                      title="Processing Order"
                      Icon={FiTruck}
                      quantity={data?.processing_orders}
                      className="text-indigo-600 bg-indigo-200"
                    />
                    <Card
                      title="Complete Orders"
                      Icon={FiCheck}
                      quantity={data?.complete_orders}
                      className="text-emerald-600 bg-emerald-200"
                    />
                  </div>
                  {/* <RecentOrder loading={load} /> */}
                  <div className="flex flex-col">
                    <h3 className="text-lg font-serif font-medium mb-5">
                      Recent Orders
                    </h3>
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="align-middle inline-block border border-gray-100 rounded-md min-w-full pb-2 sm:px-6 lg:px-8">
                        <div className="overflow-hidden border-b last:border-b-0 border-gray-100 rounded-md">
                          <table className="table-auto min-w-full border border-gray-100 divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr className="bg-gray-100">
                                <th
                                  scope="col"
                                  className="text-left text-xs font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider"
                                >
                                  ID
                                </th>
                                {/* <th
                                  scope="col"
                                  className="text-center text-xs font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider"
                                >
                                  Order Name
                                </th> */}

                                <th
                                  scope="col"
                                  className="text-center text-xs font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider"
                                >
                                  Status
                                </th>
                                <th
                                  scope="col"
                                  className="text-center text-xs font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider"
                                >
                                  Order Date
                                </th>
                                <th
                                  scope="col"
                                  className="text-center text-xs font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider"
                                >
                                  Total
                                </th>
                                <th
                                  scope="col"
                                  className="text-right text-xs font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider"
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            {getData
                              ? getData.map((el, index) => {
                                  return (
                                    <tbody className="bg-white divide-y divide-gray-200">
                                      <td className="px-5 py-3 leading-6 whitespace-nowrap">
                                        <span className="uppercase text-sm font-medium">
                                          {el.order_id}
                                        </span>
                                      </td>
                                      {/* <td className="px-5 py-3 leading-6 text-center whitespace-nowrap">
                                        <span className="text-sm">
                                          {el.products.product_name}
                                        </span>
                                      </td> */}
                                      <td className="px-5 py-3 leading-6 text-center whitespace-nowrap font-medium text-sm">
                                        {el.order_status_name ===
                                          "Order Placed" && (
                                          <span className="text-blue-500">
                                            {el.order_status_name}
                                          </span>
                                        )}
                                        {el.order_status_name ===
                                          "Order Accepted" && (
                                          <span className="text-teal-500">
                                            {el.order_status_name}
                                          </span>
                                        )}
                                        {el.order_status_name ===
                                          "Ready to Pickup" && (
                                          <span className="text-yellow-500">
                                            {el.order_status_name}
                                          </span>
                                        )}
                                        {el.order_status_name ===
                                          "Dispatched" && (
                                          <span className="text-purple-500">
                                            {el.order_status_name}
                                          </span>
                                        )}
                                        {el.order_status_name ===
                                          "In Transit" && (
                                          <span className="text-cyan-500">
                                            {el.order_status_name}
                                          </span>
                                        )}
                                        {el.order_status_name ===
                                          "Delivered" && (
                                          <span className="text-emerald-500">
                                            {el.order_status_name}
                                          </span>
                                        )}
                                        {el.order_status_name ===
                                          "Cancelled" && (
                                          <span className="text-red-500">
                                            {el.order_status_name}
                                          </span>
                                        )}
                                      </td>

                                      <td className="px-5 py-3 leading-6 text-center whitespace-nowrap">
                                        <span className="text-sm">
                                          {dayjs(el.created_at).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </span>
                                      </td>
                                      <td className="px-5 py-3 leading-6 text-center whitespace-nowrap">
                                        <span className="text-sm font-bold">
                                          ₹{el.item_price}
                                          {/* {parseFloat(order?.total).toFixed(2)} */}
                                        </span>
                                      </td>
                                      <td className="px-5 py-3 whitespace-nowrap text-right text-sm">
                                        <button
                                          onClick={() =>
                                            Navigate("/invoice", {
                                              state: {
                                                id: el.customer_id,
                                                productId: el.product_id,
                                              },
                                            })
                                          }
                                          className="px-3 py-1 bg-[#008000] text-xs text-white hover:bg-[#16b216] hover:text-white transition-all font-semibold rounded-full"
                                        >
                                          Details
                                        </button>
                                      </td>
                                    </tbody>
                                  );
                                })
                              : "No Order"}
                          </table>
                          {/* {data?.totalDoc > 10 && (
                      <div className="paginationOrder">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="Next"
                          onPageChange={(e) => handleChangePage(e.selected + 1)}
                          pageRangeDisplayed={3}
                          pageCount={pageCount}
                          previousLabel="Previous"
                          renderOnZeroPageCount={null}
                          pageClassName="page--item"
                          pageLinkClassName="page--link"
                          previousClassName="page-item"
                          previousLinkClassName="page-previous-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-next-link"
                          breakClassName="page--item"
                          breakLinkClassName="page--link"
                          containerClassName="pagination"
                          activeClassName="activePagination"
                        />
                      </div>
                    )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {children}
            </div>
          </div>
        </div>
      </Layout>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={load}
      >
        <CircularProgress color="success" />
      </Backdrop>
    </>
  );
};

export default dynamic(() => Promise.resolve(Dashboard), { ssr: false });
