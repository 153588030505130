import { Backdrop, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import Dashboard from "./Dashboard";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const RecentOrders = (props) => {
  const [getData, setGetData] = useState([]);
  const [load, setLoad] = useState(true);
  const Navigate = useNavigate();

  const GetOrderHistory = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_BASE_URL +
        `DetailByCustomerId/${localStorage.getItem("customer_id")}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setGetData(result.data);
        if (result.status === 200) {
          setLoad(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    GetOrderHistory();
    console.log("Order Data:  ", JSON.stringify(getData));
  }, []);

  const navigateOnOrderDetails = () => {
    console.log("Order Details page");
  };

  return (
    <>
      <Dashboard title="Your-Orders" description="This is edit order's page">
        {load ? (
          <div className="h-screen bg-white"></div>
        ) : (
          <>
            <div id={props.id} />
            {/* <h2 className="text-xl font-serif font-semibold mb-5">
              Recent Orders
            </h2>  */}

            {/* {getData
              ? getData.map((el, index) => {
                return (
                  <div
                    className="bg-white border-b border-gray-400 mt-2"
                    key={index}
                  >
                    <div className="flex">
                      <div className="m-1 border">
                        {/* {el.item_price} 
                        <img
                          src={
                            process.env.REACT_APP_MEDIA_BASE_URL +
                            el.products.product_base_image
                          }
                          alt=""
                          className="w-32 h-36 rounded-md"
                        />
                      </div>
                      <div className="ml-2 w-2/3">
                        <span className="font-bold text-lg text-gray-700">
                          {el.products.product_name}
                        </span>
                        <p className={`font-medium text-md text-gray-700`}>
                          <span className="font-bold">Order ID:</span>
                          {el.order_id}
                        </p>
                        <div className="font-bold">
                          Price:
                          <span className="ml-1 font-medium text-emerald-500">
                            {"₹" + el.item_price}
                          </span>
                        </div>
                        <div className="font-bold">
                          Ordered Quantity:
                          <span
                            className={`font-medium text-md text-gray-700 ml-1`}
                          >
                            {el.item_qty + " psc."}
                          </span>
                        </div>
                        <div className="font-bold">
                          Total Price:
                          <span
                            className={`font-medium text-md text-emerald-500 ml-1`}
                          >
                            {"₹" + el.total_items_price}
                          </span>
                        </div>

                        <button onClick={()=>Navigate(`/OrderDetails/${el.order_id}`)}>
                          <div className="cursor-pointer flex items-end font-medium underline text-md text-emerald-500">
                            <span >View Order Details</span>
                            <BsArrowRight className="ml-2" />
                          </div>
                        </button>

                      </div>
                    </div>
                  </div>
                );
              })
              : "No Order"} */}

            <div className="flex flex-col">
              <h2 className="text-xl font-serif font-semibold mb-8">
                My Orders
              </h2>
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="align-middle inline-block border border-gray-100 rounded-md min-w-full pb-2 sm:px-6 lg:px-8">
                  <div className="overflow-hidden border-b last:border-b-0 border-gray-100 rounded-md">
                    <table className="table-auto min-w-full border border-gray-100 divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr className="bg-gray-100">
                          <th
                            scope="col"
                            className="text-left text-xs font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider"
                          >
                            ID
                          </th>
                          {/* <th
                            scope="col"
                            className="text-center text-xs font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider"
                          >
                            Order Name
                          </th> */}

                          <th
                            scope="col"
                            className="text-center text-xs font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="text-center text-xs font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider"
                          >
                            Order Date
                          </th>
                          <th
                            scope="col"
                            className="text-center text-xs font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider"
                          >
                            Total
                          </th>
                          <th
                            scope="col"
                            className="text-right text-xs font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      {getData
                        ? getData.map((el, index) => {
                            return (
                              <tbody className="bg-white divide-y divide-gray-200">
                                <td className="px-5 py-3 leading-6 whitespace-nowrap">
                                  <span className="uppercase text-sm font-medium">
                                    {el.order_id}
                                  </span>
                                </td>
                                {/* <td className="px-5 py-3 leading-6 text-center whitespace-nowrap">
                                  <span className="text-sm">
                                    {el.products.product_name}
                                  </span>
                                </td> */}
                                <td className="px-5 py-3 leading-6 text-center whitespace-nowrap font-medium text-sm">
                                  {el.order_status_name === "Order Placed" && (
                                    <span className="text-blue-500">
                                      {el.order_status_name}
                                    </span>
                                  )}
                                  {el.order_status_name ===
                                    "Order Accepted" && (
                                    <span className="text-teal-500">
                                      {el.order_status_name}
                                    </span>
                                  )}
                                  {el.order_status_name ===
                                    "Ready to Pickup" && (
                                    <span className="text-yellow-500">
                                      {el.order_status_name}
                                    </span>
                                  )}
                                  {el.order_status_name === "Dispatched" && (
                                    <span className="text-purple-500">
                                      {el.order_status_name}
                                    </span>
                                  )}
                                  {el.order_status_name === "In Transit" && (
                                    <span className="text-cyan-500">
                                      {el.order_status_name}
                                    </span>
                                  )}
                                  {el.order_status_name === "Delivered" && (
                                    <span className="text-emerald-500">
                                      {el.order_status_name}
                                    </span>
                                  )}
                                  {el.order_status_name === "Cancelled" && (
                                    <span className="text-red-500">
                                      {el.order_status_name}
                                    </span>
                                  )}
                                </td>

                                <td className="px-5 py-3 leading-6 text-center whitespace-nowrap">
                                  <span className="text-sm">
                                    {dayjs(el.created_at).format("DD/MM/YYYY")}
                                  </span>
                                </td>
                                <td className="px-5 py-3 leading-6 text-center whitespace-nowrap">
                                  <span className="text-sm font-bold">
                                    ₹{el.item_price}
                                    {/* {parseFloat(order?.total).toFixed(2)} */}
                                  </span>
                                </td>
                                <td className="px-5 py-3 whitespace-nowrap text-right text-sm">
                                  <button
                                    onClick={() =>
                                      Navigate("/invoice", {
                                        state: { id: el.customer_id , productId: el.product_id },
                                      })
                                    }
                                    className="px-3 py-1 bg-[#008000] text-xs text-white hover:bg-[#16b216] hover:text-white transition-all font-semibold rounded-full"
                                  >
                                    Details
                                  </button>
                                </td>
                              </tbody>
                            );
                          })
                        : "No Order"}
                    </table>
                    {/* {data?.totalDoc > 10 && (
                      <div className="paginationOrder">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="Next"
                          onPageChange={(e) => handleChangePage(e.selected + 1)}
                          pageRangeDisplayed={3}
                          pageCount={pageCount}
                          previousLabel="Previous"
                          renderOnZeroPageCount={null}
                          pageClassName="page--item"
                          pageLinkClassName="page--link"
                          previousClassName="page-item"
                          previousLinkClassName="page-previous-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-next-link"
                          breakClassName="page--item"
                          breakLinkClassName="page--link"
                          containerClassName="pagination"
                          activeClassName="activePagination"
                        />
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={load}
        >
          <CircularProgress color="success" />
        </Backdrop>
      </Dashboard>
    </>
  );
};

export default RecentOrders;
